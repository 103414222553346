@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.App {
  text-align: center;
  @font-family: 'Fredoka One', cursive;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  display: flex;
  justify-content: center;
  font-family: 'Fredoka One', cursive;

}

.a-remove-underline {
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

.modal-body {
  display: flex;
  padding: 0;
  border-radius: 4rem;
  font-family: 'Fredoka One', cursive;
}

.modal-content {
  border-radius: 4rem;
  width: 140%;
  -webkit-box-shadow: -1px -2px 42px -19px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: -1px -2px 42px -19px rgba(0, 0, 0, 0.74);
  box-shadow: -1px -2px 42px -19px rgba(0, 0, 0, 0.74);
}

.modal-content h1,
.modal-content h2,
.modal-content h3 {
  text-align: center;
}

.modal-content h1 {
  font-size: 1.3em;
  text-transform: uppercase;
}

.modal-content h2 {
  font-size: 1.1em;
}

.modal-content h3 {
  font-size: .8em;
  letter-spacing: 2px;
}

form {
  font-size: .8em;
}

.column {
  flex: 50%;
  padding: 10px;
}

.column#main {
  flex: 75%;
  padding: 50px;
  margin-top: 30px;
  margin-left: 15px;
}

#secondary {
  background-color: #594cda;
  border-radius: 0 4rem 4rem 0;
  text-align: center;
  color: #fff !important;
}

#main .form-control {
  border-radius: 0;
  font-size: .9em;
}

.btn-primary:hover {
  color: #000 !important;
}

.btn-primary {
  border-color: rgba(255, 255, 255, 0);
  color: white;
  background: #f1da36;
  /* Old browsers */
  background: -moz-linear-gradient(#594cda, #594cda);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #594cda 0%, #09d3ac 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(#594cda, #594cda);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1da36', endColorstr='#fca86c', GradientType=1);
  /* IE6-9 */

}

.btn-primary:hover {
  border-color: rgba(255, 255, 255, 0);
}

#main .btn-primary {
  width: 100%;
}

#secondary .btn-primary {
  background: #f8f9fa4f;
  color: #000;
}

.modal-body label {
  margin-bottom: 0;
}

.sec-content {
  margin-top: 85%;

}

.white-text {
  color: #fff !important;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(203, 243, 240, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #111111 transparent #030303 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}